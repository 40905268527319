import UserWorkspace from "../../components/workspace"
import AdminWrapper from "./wrapper"



const AdminWorkspace =()=>{
    return(
        <AdminWrapper>
            <UserWorkspace/>
        </AdminWrapper>

    )
}

export default AdminWorkspace;