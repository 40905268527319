import UsersScreen from "./UsersScreen"
import AdminWrapper from "./wrapper"



const AdminUsers= ()=>{
    return(
        <AdminWrapper>
            <UsersScreen/>
        </AdminWrapper>
    )
}

export default AdminUsers