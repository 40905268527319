import UserScreen from "../User/UserScreen"
import AdminWrapper from "./wrapper"



const User= ()=>{
    return(
        <AdminWrapper>
            <UserScreen/>
        </AdminWrapper>
    )
}

export default User