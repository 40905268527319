export const GET_JIRA_LABELS_REQUEST = 'GET_JIRA_LABELS_REQUEST';
export const GET_JIRA_LABELS_SUCCESS = 'GET_JIRA_LABELS_SUCCESS';
export const GET_JIRA_LABELS_FAILURE = 'GET_JIRA_LABELS_FAILURE';

export const GET_ALL_ISSUES_REQUEST = 'GET_ALL_ISSUES_REQUEST';
export const GET_ALL_ISSUES_SUCCESS = 'GET_ALL_ISSUES_SUCCESS';
export const GET_ALL_ISSUES_FAILURE = 'GET_ALL_ISSUES_FAILURE';

export const GET_LABEL_ISSUES_REQUEST = 'GET_LABEL_ISSUES_REQUEST';
export const GET_LABEL_ISSUES_SUCCESS = 'GET_LABEL_ISSUES_SUCCESS';
export const GET_LABEL_ISSUES_FAILURE = 'GET_LABEL_ISSUES_FAILURE';

export const CLEAR_LABEL_ISSUES = 'CLEAR_LABEL_ISSUES';
export const SET_JIRA_TEXT_FILTER = 'SET_JIRA_TEXT_FILTER';
export const SET_JIRA_ASSIGNEDTO_FILTER = 'SET_JIRA_ASSIGNEDTO_FILTER';
export const SET_JIRA_STATUS_FILTER = 'SET_JIRA_STATUS_FILTER';
